import React, { useState, useEffect, Fragment } from "react";
import { toast } from "react-toastify";
import CurrencyFormat from "react-currency-format";
import { useHistory } from "react-router-dom";
import API from "../../../helpers/api";
import FNSpinner from "../../../components/FNSpinner";
import Select from "react-select/base";
import EditSpare from "./EditSpare";
import JobCardData from "../../../components/JobCardData/JobCardData";
import UpdateArrayComponent from "./UpdatedArray";

import jobCardImage from "../../../assets/images/proformaInvoice.png";


const AddProforma = ({ match }) => {
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [jobcard, setJobCard] = useState({});
  const [subTotal, setSubTotal] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [grossTotal, setGrossTotal] = useState(0);
  const [vat, setVat] = useState(0);
  const [netTotal, setNetTotal] = useState(0);
  const [hasvat, setHasVat] = useState(false);
  const [hasdiscount, setHasDiscount] = useState(false);
  const [vehicle, setVehicle] = useState(false);
  const [spareParts, setSparePart] = useState([]);

  const [spare, setSpare] = useState({
    partname: "",
    partno: "",
    spareId: "",
    JobCardId: 0,
    measure: "",
    unitPrice: 0,
    qtyUsed: 0,
  });

  const history = useHistory();
  const { id } = match.params;

  const loadJobCard = async () => {
    setLoading(true);
    try {
      const res = await API.get(`/jobcard/${id}`);
      await fetchVehicleDetails(res?.data.job.vehicleId);
      await loadSpareParts();

      setJobCard(res?.data.job);

      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  const fetchVehicleDetails = async (id) => {
    try {
      const res = await API.get(`/vehicle/${id}`);
      console.log(res);
      setVehicle(res?.data.vehicle);
    } catch (error) {
      console.log("Error fetching vehicle details", error);
    }
  };

  const loadSpareParts = async () => {
    // setLoading(true);
    try {
      const res = await API.get(`/jobcard/spare/${id}`);
      //   console.log("spare====", res?.data?.spareparts);

      const newArray = await Promise.all(
        res?.data?.spareparts.map(async (item) => {
          const spareTotal = await spareQty({ spareId: item.spareId });

          return {
            ...item, // Spread the old fields
            spareTotalQty: spareTotal, // Use the resolved value for the new field
          };
        })
      );

      console.log("Updated Array:", newArray);

      setSparePart(newArray);
      // setLoading(false);
    } catch (error) {
      console.log("error", error);
      // setLoading(false);
    }
  };

  const spareQty = async ({ spareId }) => {
    try {
      setLoading(true);
      const res = await API.get(`/sparestore/qty/${spareId}`);
      setLoading(false);
      return res.data.qty;
      // console.log("vvvvvvvvvv", res);
      // setSparePart(res?.data?.spareparts);
    } catch (error) {
      console.log("error", error);
      // setLoading(false);
    }
  };

  const createNewRow = () => ({
    id: Date.now(),
    item: "",
    qty: "",
    rate: "",
    amt: 0,
  });

  const addRow = () => {
    setRows([...rows, createNewRow()]);
  };

  const handleInputChange = (id, field, value) => {
    setRows((prevRows) =>
      prevRows.map((row) => {
        if (row.id === id) {
          const updatedRow = { ...row, [field]: value };
          if (field === "qty" || field === "rate") {
            const qty =
              field === "qty"
                ? parseFloat(value) || 0
                : parseFloat(updatedRow.qty) || 0;
            const rate =
              field === "rate"
                ? parseFloat(value) || 0
                : parseFloat(updatedRow.rate) || 0;
            updatedRow.amt = qty * rate;
          }
          return updatedRow;
        }
        return row;
      })
    );
  };

  const deleteRow = (id) => {
    setRows((prevRows) => prevRows.filter((row) => row.id !== id));
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   setLoading(true);

  //   const reqData = {
  //     partname: "",
  //     partno: "",
  //     spareId: "",
  //     JobCardId: 0,
  //     measure: "",
  //     unitPrice: 0,
  //     qtyUsed: 0,
  //   };

  //   const data = {
  //     jobcardId: id,
  //     subTotal,
  //     discount,
  //     netTotal,
  //     vat,
  //     grossTotal,
  //     rows: rows.map((row) => ({
  //       item: row.item,
  //       qty: row.qty,
  //       rate: row.rate,
  //       amt: row.amt,
  //     })),
  //   };

  //   try {
  //     const response = await API.post("/proforma", data);
  //     setLoading(false);
  //     history.push("/proforma");
  //     toast.success(`Proforma Has Been Added Successfully`);
  //   } catch (error) {
  //     console.log("error", error);
  //     setLoading(false);
  //     toast.error("Error while Adding Proforma");
  //   }
  // };

  useEffect(() => {
    loadJobCard();
    setRows([createNewRow()]);
  }, []);

  return (
    <Fragment>
      <div className="row">
        <div className="col-12">
          <h4 className="mb-sm-0 font-size-18">
            Create Proforma Invoice for Job Card: #{id}
          </h4>
          {/* {loading ? (
            <p>Loading...</p>
          ) : (
            <> {spareQty("cfd7b2e9-fba7-4434-882c-1cd1d1a11a42")}</>
          )} */}
          <JobCardData id={id} image={jobCardImage} />
          <EditSpare id={id} showFooter={true}/>

        
        </div>
      </div>
    </Fragment>
  );
};

export default AddProforma;
