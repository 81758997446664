import React, { useState, useEffect, Fragment } from "react";
import { toast } from "react-toastify";
import CurrencyFormat from "react-currency-format";
import API from "../../../helpers/api";
import FNSpinner from "../../../components/FNSpinner";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import footerImage from "../../../assets/images/proformaFooter.png";

const EditSpare = ({ id, showFooter }) => {
  const [loading, setLoading] = useState(false);
  const [spareParts, setSpareParts] = useState([]);
  const [jobcard, setJobCard] = useState({});
  const [subTotal, setSubTotal] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [grossTotal, setGrossTotal] = useState(0);
  const [vat, setVat] = useState(0);
  const [netTotal, setNetTotal] = useState(0);
  const [hasVat, setHasVat] = useState(false);
  const [hasDiscount, setHasDiscount] = useState(false);
  const [vehicle, setVehicle] = useState(null);
  const history = useHistory();
  useEffect(() => {
    loadJobCard();
  }, []);

  useEffect(() => {
    calculateTotals();
  }, [spareParts, hasVat, hasDiscount, discount]);

  const loadJobCard = async () => {
    setLoading(true);
    try {
      const res = await API.get(`/jobcard/${id}`);
      await fetchVehicleDetails(res?.data.job.vehicleId);
      await loadSpareParts();
      setJobCard(res?.data.job);
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      toast.error("Error loading job card");
    }
  };

  const fetchVehicleDetails = async (vehicleId) => {
    try {
      const res = await API.get(`/vehicle/${vehicleId}`);
      setVehicle(res?.data.vehicle);
    } catch (error) {
      console.log("Error fetching vehicle details", error);
      toast.error("Error fetching vehicle details");
    }
  };

  // const loadSpareParts = async () => {
  //   try {
  //     const res = await API.get(`/jobcard/spare/${id}`);
  //     const partsWithOriginal = res?.data?.spareparts.map((part) => ({
  //       ...part,
  //       originalQty: part.qtyUsed,
  //       originalPrice: part.unitPrice,
  //     }));
  //     setSpareParts(partsWithOriginal);
  //   } catch (error) {
  //     console.log("error", error);
  //     toast.error("Error loading spare parts");
  //   }
  // };

  const loadSpareParts = async () => {
    // setLoading(true);
    try {
      const res = await API.get(`/jobcard/spare/${id}`);
      //   console.log("spare====", res?.data?.spareparts);

      const newArray = await Promise.all(
        res?.data?.spareparts.map(async (item) => {
          const spareTotal = await spareQty({ spareId: item.spareId });

          return {
            ...item, // Spread the old fields
            spareTotalQty: spareTotal, // Use the resolved value for the new field
          };
        })
      );

      console.log("Updated Array:", newArray);

      setSpareParts(newArray);
      // setLoading(false);
    } catch (error) {
      console.log("error", error);
      // setLoading(false);
    }
  };

  const spareQty = async ({ spareId }) => {
    try {
      setLoading(true);
      const res = await API.get(`/sparestore/qty/${spareId}`);
      setLoading(false);
      return res.data.qty;
      // console.log("vvvvvvvvvv", res);
      // setSparePart(res?.data?.spareparts);
    } catch (error) {
      console.log("error", error);
      // setLoading(false);
    }
  };

  const upDateSpareQty = async ({ spareId, qty }) => {
    try {
      // setLoading(true);
      const res = await API.patch(`/sparestore/remove/${spareId}`, {
        qty: qty,
      });
      // setLoading(false);
      // return res.data.qty;
      console.log("successfully updated!");
      // setSparePart(res?.data?.spareparts);
    } catch (error) {
      console.log("error", error);
      // setLoading(false);
    }
  };

  const handleInputChange = (id, field, value) => {
    setSpareParts((prevParts) =>
      prevParts.map((part) =>
        part.id === id ? { ...part, [field]: value } : part
      )
    );
  };

  const calculateTotals = () => {
    const subTotal = spareParts.reduce(
      (sum, part) => sum + part.qtyUsed * part.unitPrice,
      0
    );

    const discountAmount = hasDiscount ? (subTotal * discount) / 100 : 0;
    const netTotal = subTotal - discountAmount;
    const vatAmount = hasVat ? netTotal * 0.18 : 0;
    const grossTotal = netTotal + vatAmount;

    setSubTotal(subTotal);
    setNetTotal(netTotal);
    setVat(vatAmount);
    setGrossTotal(grossTotal);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      // const modifiedParts = spareParts.filter(
      //   (part) =>
      //     part.qtyUsed !== part.originalQty ||
      //     part.unitPrice !== part.originalPrice
      // );

      // if (modifiedParts.length === 0) {
      //   toast.info("No changes to submit");
      //   setLoading(false);
      //   return;
      // }

      const data = {
        data: {
          jobcardId: id,
          subTotal,
          discount,
          netTotal,
          vat,
          grossTotal,
          hasVat,
          hasDiscount,
        },

        spareParts: spareParts,
      };

      const response = await API.post("/proforma", data);

      data?.spareParts.map(async (item) => {
        const spareTotal = await upDateSpareQty({
          spareId: item.spareId,
          qty: item.qtyUsed,
        });
      });

      setLoading(false);

      history.push("/proforma");
      toast.success("Proforma has been updated successfully");

      // Update original values after successful save
      setSpareParts((parts) =>
        parts.map((part) => ({
          ...part,
          originalQty: part.qtyUsed,
          originalPrice: part.unitPrice,
        }))
      );
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      toast.error("Error while updating Proforma");
    }
  };
  console.log("902335", spareParts);
  return (
    <Fragment>
      <div className="container-fluid bg-white">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title mb-4">Vehicle Spare Parts</h4>
                <form onSubmit={handleSubmit}>
                  <div className="d-flex mb-3">
                    <div className="form-check me-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="hasVat"
                        checked={hasVat}
                        onChange={(e) => setHasVat(e.target.checked)}
                      />
                      <label className="form-check-label" htmlFor="hasVat">
                        Has VAT
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="hasDiscount"
                        checked={hasDiscount}
                        onChange={(e) => setHasDiscount(e.target.checked)}
                      />
                      <label className="form-check-label" htmlFor="hasDiscount">
                        Has Discount
                      </label>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table className="table table-striped table-sm">
                      <thead className="table-light">
                        <tr>
                          <th style={{ width: "50%" }}>Spare part</th>
                          <th style={{ width: "5%" }}>Qty Available</th>
                          <th style={{ width: "5%" }}>Qty</th>
                          <th style={{ width: "10%" }}>Unit Price</th>
                          <th
                            style={{ width: "12%" }}
                          >{`Amount (${jobcard?.currency})`}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {spareParts.map((part) => (
                          <tr key={part.id}>
                            <td>{part.partname}</td>
                            <td>{part.spareTotalQty}</td>
                            <td>
                              <input
                                type="number"
                                className="form-control"
                                value={part.qtyUsed}
                                onChange={(e) =>
                                  handleInputChange(
                                    part.id,
                                    "qtyUsed",
                                    parseFloat(e.target.value)
                                  )
                                }
                              />
                            </td>
                            <td>
                              <CurrencyFormat
                                value={part.unitPrice}
                                thousandSeparator
                                disabled={true}
                                className="form-control"
                                onValueChange={(values) =>
                                  handleInputChange(
                                    part.id,
                                    "unitPrice",
                                    values.floatValue || 0
                                  )
                                }
                              />
                            </td>
                            <td className="text-end">
                              <CurrencyFormat
                                value={part.qtyUsed * part.unitPrice || 0}
                                thousandSeparator
                                displayType="text"
                                prefix={`${jobcard?.currency} `}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colSpan={3} className="text-end">
                            <strong>Sub Total Amount</strong>
                          </td>
                          <td className="text-end">
                            <CurrencyFormat
                              value={subTotal || 0}
                              displayType="text"
                              thousandSeparator
                              prefix={`${jobcard?.currency} `}
                            />
                          </td>
                        </tr>
                        {hasDiscount && (
                          <tr>
                            <td colSpan={3} className="text-end">
                              <strong>Discount (%)</strong>
                            </td>
                            <td className="text-end">
                              <input
                                type="number"
                                className="form-control"
                                value={discount || 0}
                                onChange={(e) =>
                                  setDiscount(parseFloat(e.target.value) || 0)
                                }
                              />
                            </td>
                          </tr>
                        )}
                        <tr>
                          <td colSpan={3} className="text-end">
                            <strong>Net Total Amount</strong>
                          </td>
                          <td className="text-end">
                            <CurrencyFormat
                              value={netTotal || 0}
                              displayType="text"
                              thousandSeparator
                              prefix={`${jobcard?.currency} `}
                            />
                          </td>
                        </tr>
                        {hasVat && (
                          <tr>
                            <td colSpan={3} className="text-end">
                              <strong>VAT 18%</strong>
                            </td>
                            <td className="text-end">
                              <CurrencyFormat
                                value={vat || 0}
                                displayType="text"
                                thousandSeparator
                                prefix={`${jobcard?.currency} `}
                              />
                            </td>
                          </tr>
                        )}
                        <tr>
                          <td colSpan={3} className="text-end">
                            <strong>Gross Total Amount</strong>
                          </td>
                          <td className="text-end">
                            <CurrencyFormat
                              value={grossTotal || 0}
                              displayType="text"
                              thousandSeparator
                              prefix={`${jobcard?.currency} `}
                            />
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                  {/* <div className="text-end mt-4">
                  <button
                    type="submit"
                    className="btn btn-primary w-md waves-effect waves-light"
                    disabled={loading}
                  >
                    {loading ? <FNSpinner /> : "Update Proforma"}
                  </button>
                </div> */}
                </form>
              </div>
            </div>

            {showFooter ? (
              <div
                className="w-100 "
                style={{
                  width: "100%",
                }}
              >
                <img
                  src={footerImage}
                  alt=""
                  style={{
                    width: "100%",
                    // height: "200px",
                    objectFit: "contain",
                    objectPosition: "bottom",
                  }}
                />
              </div>
            ) : null}

            <div className="d-flex justify-content-end py-2">
              {" "}
              <button
                onClick={handleSubmit}
                className="btn btn-primary btn-lg w-md waves-effect waves-light"
              >
                {loading ? <FNSpinner /> : "Create Proforma"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default EditSpare;
