import React, { useState, useEffect, Fragment } from "react";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import API from "../../../helpers/api";
import FNTable from "../../../components/FNTable1";
import FSpinner from "../../../components/FNSpinner/FSpinner";

const JobCards = () => {
  const [jobcards, setJobCards] = useState([]);
  const [loading, setLoading] = useState([]);

  const history = useHistory();

  const loadJobCards = async () => {
    setLoading(true);
    try {
      const res = await API.get("/jobcard");

      const formattedJobs = res.data.job.map((job) => ({
        ...job,
        createdAt: moment(job.createdAt).format("YYYY-MM-DD"),
        //jobCard: job.id.substring(0, 8),
        // status: <span class="badge bg-warning">{ticket.status}</span>
      }));
      console.log(res);
      setJobCards(formattedJobs);
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  const jobCardAdd = (id) => {
    history.push(`/proforma/create/${id}`);
  };

  const jobCardDetails = (id) => {
    history.push(`/jobcard/print/${id}`);
  };

  const jobCardUpdate = (id) => {
    history.push(`/jobcard/update/${id}`);
  };

  useEffect(() => {
    loadJobCards();
  }, []);

  const tableColumns = [
    { key: "vehicle.numberplate", label: "Number Plate" },
    { key: "driverOrCustomerName", label: "Customer Name" },
    { key: "dateTested", label: "Date Tested" },
    { key: "paymentMethod", label: "Payment method" },
    { key: "technicianName", label: "Technician Name" },
  ];

  return (
    <Fragment>
      <div class="row">
        <div class="col-12">
          <div class="page d-sm-flex align-items-center justify-content-between">
            <h4 class="mb-sm-0 font-size-18">Job cards</h4>
            <div class="page-title-right">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item">
                  <Link to="/ict/jobcards">Vehicles</Link>
                </li>
                <li class="breadcrumb-item active">Job Card</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-body">
                  <div class="row mb-2">
                    <div class="col-sm-4">
                      <div class="search-box me-2 mb-2 d-inline-block">
                        <div class="position-relative">
                          <input
                            type="text"
                            class="form-control"
                            id="searchTableList"
                            placeholder="Search..."
                          />
                          <i class="bx bx-search-alt search-icon"></i>
                        </div>
                      </div>
                    </div>
                    {/* <div class="col-sm-8">
                                              <div class="text-sm-end">
                                                  <button type="submit" class="btn btn-primary waves-effect waves-light" onClick={handleShow}>Add Garage Job Card</button>
                                              </div>
                                          </div> */}
                  </div>
                  {loading ? (
                    <FSpinner />
                  ) : (
                    <FNTable
                      columns={tableColumns}
                      data={jobcards}
                      onViewDetails={jobCardDetails}
                      handleEdit={jobCardAdd}
                      handleUpdate={jobCardUpdate}
                      title1="View Details"
                      title2="Add Proforma"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
    // <div className="row">
    //   <div className="col-8">
    //     {" "}

    //   </div>
    //   <div className="col-4">
    //     <pre className="mt-4 p-3 bg-light rounded">
    //       {JSON.stringify(jobcards, null, 2)}
    //     </pre>
    //   </div>
    // </div>
  );
};

export default JobCards;
