import React, { Fragment, useState, useEffect, useRef } from "react";
import image from "../../../assets/images/invoiceImage.png";
import Print from "../proforma/Print";

const PrintInvoice = ({ match }) => {
  const { id } = match.params;

  return (
    <>
      <Print id={id} image={image} />
      {/* <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <>
              {" "}
              <Fragment>
                <div
                  className="row d-flex justify-content-center"
                  ref={componentRef}
                >
                  <div className="col-10">
                    <div className="card">
                      <div className="card-body">
         
                        <div>
                          {proforma.jobcard ? (
                            <JobCardData id={proforma.jobcard.id} />
                          ) : (
                            <p>Loading</p>
                          )}
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <div className="card">
                              <div className="card-body">
                                <h4 className="card-title mb-4">
                                  Vehicle Spare Parts
                                </h4>
                                <form>
                                  <div className="table-responsive">
                                    <table className="table table-striped table-sm">
                                      <thead className="table-light">
                                        <tr>
                                          <th style={{ width: "50%" }}>
                                            Spare part
                                          </th>
                                          <th style={{ width: "8%" }}>Qty</th>
                                          <th style={{ width: "12%" }}>
                                            Unit Price
                                          </th>
                                          <th
                                            style={{ width: "12%" }}
                                          >{`Amount (${proforma?.jobcard?.currency})`}</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {spareParts?.map((part) => (
                                          <tr key={part.id}>
                                            <td>{part.partname}</td>
                                            <td>
                                              <input
                                                type="number"
                                                className="form-control"
                                                value={part.qtyUsed}
                                                disabled
                                                // onChange={(e) =>
                                                //   handleInputChange(
                                                //     part.id,
                                                //     "qtyUsed",
                                                //     parseFloat(e.target.value)
                                                //   )
                                                // }
                                              />
                                            </td>
                                            <td>
                                              <CurrencyFormat
                                                value={part.unitPrice}
                                                thousandSeparator
                                                disabled={true}
                                                className="form-control"
                                                // onValueChange={(values) =>
                                                //   handleInputChange(
                                                //     part.id,
                                                //     "unitPrice",
                                                //     values.floatValue || 0
                                                //   )
                                                // }
                                              />
                                            </td>
                                            <td className="text-end">
                                              <CurrencyFormat
                                                disabled={true}
                                                value={
                                                  part.qtyUsed *
                                                    part.unitPrice || 0
                                                }
                                                thousandSeparator
                                                displayType="text"
                                                prefix={`${proforma?.jobcard?.currency} `}
                                              />
                                            </td>
                                          </tr>
                                        ))}
                                      </tbody>
                                      <tfoot className="">
                                        <tr className="">
                                          <td colSpan={3} className="text-end">
                                            <strong>Sub Total Amount</strong>
                                          </td>
                                          <td className="text-end">
                                            <CurrencyFormat
                                              value={proforma?.subTotal || 0}
                                              displayType="text"
                                              thousandSeparator
                                              prefix={`${proforma?.jobcard?.currency} `}
                                            />
                                          </td>
                                        </tr>

                                        <tr>
                                          <td colSpan={3} className="text-end">
                                            <strong>Discount (%)</strong>
                                          </td>
                                          <td className="text-end">
                                            <input
                                              type="number"
                                              disabled
                                              className="form-control"
                                              value={proforma?.discount || 0}
                                       
                                            />
                                          </td>
                                        </tr>

                                        <tr>
                                          <td colSpan={3} className="text-end">
                                            <strong>Net Total Amount</strong>
                                          </td>
                                          <td className="text-end">
                                            <CurrencyFormat
                                              value={proforma?.netTotal || 0}
                                              displayType="text"
                                              disabled={true}
                                              thousandSeparator
                                              prefix={`${proforma?.jobcard?.currency} `}
                                            />
                                          </td>
                                        </tr>

                                        <tr>
                                          <td colSpan={3} className="text-end">
                                            <strong>VAT 18%</strong>
                                          </td>
                                          <td className="text-end">
                                            <CurrencyFormat
                                              value={proforma?.vat || 0}
                                              displayType="text"
                                              disabled={true}
                                              thousandSeparator
                                              prefix={`${proforma?.jobcard?.currency} `}
                                            />
                                          </td>
                                        </tr>
                                        <tr>
                                          <td colSpan={3} className="text-end">
                                            <strong>Gross Total Amount</strong>
                                          </td>
                                          <td className="text-end">
                                            <CurrencyFormat
                                              value={proforma?.grossTotal || 0}
                                              displayType="text"
                                              disabled={true}
                                              thousandSeparator
                                              prefix={`${proforma?.jobcard?.currency} `}
                                            />
                                          </td>
                                        </tr>
                                      </tfoot>
                                    </table>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                        <footer>
                          <ul className="list-unstyled">
                            <li>STANBIC BANK : 9030017839968 - GARDEN CITY.</li>
                            <li>
                              AC NAME: SSEJP ENGINEERING AND CONSULTS-SMC LTD
                            </li>
                            <li> SWIFT: SBICUGKX</li>
                          </ul>
                        </footer>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row d-flex justify-content-center mb-3">
                  <div className="col-10">
                    <div class="d-print-none">
                      <div class="float-end">
                        <ReactToPrint
                          trigger={() => (
                            <button class="btn btn-warning w-md waves-effect waves-light">
                              Print Vehicle Invoice
                            </button>
                          )}
                          content={() => componentRef.current}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Fragment>
            </>
          </div>
       
        </div>
      </div> */}
    </>
  );
};

export default PrintInvoice;
