
import Print from "./Print";
import image from "../../../assets/images/proformaInvoice.png";

const PrintProforma = ({ match }) => {
  const { id } = match.params;
  return (
    <div>
      <Print id={id} image={image}/>
    </div>
  );
};

export default PrintProforma;
